import React, { useEffect } from "react"
import { Link } from "gatsby"
import { connect } from "react-redux"
import {
  getProductsByCategory,
  addToCart,
  getProducts,
} from "../store/actions/productActions"
import { getCategory } from "../store/actions/categoryActions"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Slider2 from "../components/slider2"
import { BASE_URL } from "../utils/urls"

const ShopByCategory = ({
  getProductsByCategory,
  isLoading,
  productsByCategory,
  addToCart,
  getCategory,
  category,
  getProducts,
  products,
}) => {
  useEffect(() => {
    getProducts().then(() => {
      getCategory(3).then(() => {
        getProductsByCategory(3)
      })
    })
  }, [])
  const pageName = category.name ? category.name : "TARIFS ET COMMANDE"
  return (
    <Layout>
      <SEO title={pageName} />
      <Slider2 title={pageName} />
      <div className="flex flex-col justify-center py-6">
        <div className="container mx-auto">
          <div className="flex flex-col">
            <span className="text-center text-sm pt-2 font-semibold text-blue-600">
              Tarifs et commande
            </span>
            <h1 className="text-center text-2xl">
              Des vêtements à faire laver ?
            </h1>
            <span className="text-gray-600 text-xs text-center px-4 pb-8">
              Vous êtes des personnes exceptionnelles, voilà pourquoi
              <br />
              Mon ePressing traite vos vêtements exceptionnellement.
            </span>
          </div>
          <div className="flex flex-wrap pb-4">
            {isLoading && (
              <div className="w-full mx-5">
                <div
                  className="flex items-center bg-blue-500 text-white text-sm font-bold px-4 py-3"
                  role="alert"
                >
                  <svg
                    className="fill-current w-4 h-4 mr-2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M12.432 0c1.34 0 2.01.912 2.01 1.957 0 1.305-1.164 2.512-2.679 2.512-1.269 0-2.009-.75-1.974-1.99C9.789 1.436 10.67 0 12.432 0zM8.309 20c-1.058 0-1.833-.652-1.093-3.524l1.214-5.092c.211-.814.246-1.141 0-1.141-.317 0-1.689.562-2.502 1.117l-.528-.88c2.572-2.186 5.531-3.467 6.801-3.467 1.057 0 1.233 1.273.705 3.23l-1.391 5.352c-.246.945-.141 1.271.106 1.271.317 0 1.357-.392 2.379-1.207l.6.814C12.098 19.02 9.365 20 8.309 20z" />
                  </svg>
                  <div>Chargement en cours</div>
                </div>
              </div>
            )}
            {productsByCategory.map((product, key) => (
              <div
                className="w-full sm:w-1/2 md:w-1/2 lg:w-1/4 px-5 py-5"
                key={key}
              >
                <div className="max-w-sm rounded overflow-hidden shadow-lg bg-white">
                  <img
                    className="w-full mx-auto"
                    style={{
                      height: "300px",
                      width: "auto",
                      alignSelf: "center",
                    }}
                    src={BASE_URL + "/storage/" + product.cover}
                    alt=""
                  />
                  <div className="py-2 text-center mx-4">
                    <div className="font-bold text-xl mb-2">{product.name}</div>
                    <div className="flex flex-col my-3">
                      <span className="text-base">
                        {product.selling_price + " FCFA"}
                      </span>

                      <div className="text-gray-600 text-xs pt-5">
                        <button
                          onClick={() => addToCart(product.id)}
                          className="px-2 py-2 rounded-full font-semibold bg-gray-100 border-gray-200 hover:bg-gray-200 hover:bg-gray-200"
                        >
                          Ajouter au panier
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="flex justify-center py-6">
            <Link
              to="/contact-us"
              className="text-white bg-blue-600 border-blue-600 hover:bg-blue-800 hover:border-blue-800 font-semibold py-2 px-4 rounded-lg text-center shadow-lg text-xs"
            >
              Un vêtement inexistant dans notre liste ?
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  )
}

const mapStateToProps = state => {
  return {
    isLoading: state.product.isLoading,
    productsByCategory: state.product.productsByCategory,
    category: state.category.category,
    products: state.product.products,
  }
}

const mapDispatchToProps = {
  getProductsByCategory,
  addToCart,
  getCategory,
  getProducts,
}

export default connect(mapStateToProps, mapDispatchToProps)(ShopByCategory)
